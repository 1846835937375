.main-container {
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 900px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .container-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      g {
        opacity: 0;
        fill: rgba(119, 175, 130, 0.7);
        stroke: rgb(255, 255, 255);
        stroke-width: 1;

        &:hover {
          fill: rgba(110, 199, 127, 0.9);
        }
      }
    }
  }

  .container-btn-rotation-and-zoom {
    position: absolute;
    z-index: 10;
    display: flex;
    bottom: 1rem;
    left: 50%;
    transform: translate(-50%);
    flex-direction: row-reverse;

    &.he {
      flex-direction: row;
    }

    .model-btn {
      font-size: 0.9375rem;
      flex: 0 0 auto;
      height: 2.625rem;
      min-width: 2.625rem;
      padding: 0;

      & + .model-btn {
        margin-right: 1rem;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column !important;
      top: 50%;
      bottom: 0;
      left: 1rem;
      transform: translate(0, -50%);

      .model-btn {
        font-size: 0.8rem;
        height: 2.15rem;
        min-width: 2.15rem;

        & + .model-btn {
          margin-right: 0;
          margin-top: 1rem;
        }
      }
    }
  }
}
