.transitionGroup {
    height: 100%
}
.page-enter, .mobilePage-enter, .mobile-footer-enter, .mobile-content-enter, .preview-popup-content-enter {
    opacity: 0;
}

.page-enter {
    z-index: 10;
}

.page-enter-active, .mobilePage-enter-active, .mobile-footer-enter-active, .mobile-content-enter-active, .preview-popup-content-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}
.mobilePage-exit, .page-exit, .mobile-footer-exit, .mobile-content-exit, .preview-popup-content-exit {
    opacity: 1;
}
.page-exit, .mobile-footer-exit {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.page-exit-active, .mobilePage-exit-active, .mobile-footer-exit-active, .mobile-content-exit-active, .preview-popup-content-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}